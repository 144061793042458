@use './variables';

.btn-bac-primary {
  background-color: $bac-white;
  color: $bac-blue;
  border-radius: 2px;
  font-family: $font-family-open;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid $bac-blue;
  padding: 12px 16px;
  @media (max-width: $sm) {
    width: 100%;
    padding: 7px 10px;
  }

  &:disabled {
    background-color: $christmas-silver-rak !important;
    color: $white-rak !important;
  }
  &:hover {
    background-color: $bac-blue-1;
    color: $bac-blue;
  }
}
.btn-bac-secondary  {
  background-color: #018CC9;
  color: $bac-white;
  border-radius: 2px;
  font-family: $font-family-open;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid $bac-blue;
  padding: 12px 16px;
  &:disabled {
    border: 1px solid #FFFFFF;
  }
  @media (max-width: $sm) {
    width: 100%;
    padding: 7px 10px;
  }

  &:disabled {
    background-color: $christmas-silver-rak !important;
    color: $white-rak !important;
  }
  &:hover {
    background-color: #009CDF;
    color: $white;
  }
}
.btn-bac-thirdy {
  background-color: $bac-white;
  color: $bac-blue;
  border-radius: 2px;
  font-family: $font-family-open;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid $bac-white;
  padding: 12px 16px;
  @media (max-width: $sm) {
    width: 100%;
    padding: 7px 10px;
  }

  &:disabled {
    background-color: $christmas-silver-rak !important;
    color: $white-rak !important;
  }
  &:hover {
    background-color: $bac-blue-1;
    border: 1px solid $bac-blue-1;
    color: $bac-blue;
  }
}